<adxad-loader
  adxadSidebarModalLoader
  [diameter]="40"
  *ngIf="isLoading || isLoadingStatus"
></adxad-loader>

<adxad-sidebar-modal-container *ngIf="!isLoading">
  <adxad-sidebar-modal-header>
    <adxad-sidebar-modal-close-btn (click)="closeModal()"></adxad-sidebar-modal-close-btn>
    <h1 adxadSidebarModalTitle>{{ user.firstName }} {{ user.lastName }}</h1>
  </adxad-sidebar-modal-header>

  <adxad-sidebar-modal-body class="user-view">
    <section adxadSidebarModalSection>
      <div class="row">
        <div
          class="col-4"
          *ngIf="user.avatar"
        >
          <img
            class="avatar"
            src="{{ user.avatar }}"
            alt="{{ user.firstName }} {{ user.lastName }}"
          />
        </div>
        <div [class]="user.avatar ? 'col-8' : 'col-12'">
          <div class="row">
            <adxad-sidebar-modal-info-block
              class="col-6"
              label="Created at"
            >
              {{ user.createdAt | date: 'MM/dd/y H:mm:ss' }}
            </adxad-sidebar-modal-info-block>

            <adxad-sidebar-modal-info-block
              class="col-6"
              label="Updated at"
            >
              {{ user.updatedAt | date: 'MM/dd/y H:mm:ss' }}
            </adxad-sidebar-modal-info-block>

            <adxad-sidebar-modal-info-block
              class="col-6"
              label="Status"
            >
              <adxad-entity-status [entityStatus]="user.status"></adxad-entity-status>
            </adxad-sidebar-modal-info-block>

            <adxad-sidebar-modal-info-block
              class="col-6"
              label="Role"
            >
              {{ user.role }}
            </adxad-sidebar-modal-info-block>
          </div>
        </div>
      </div>
    </section>

    <section
      adxadSidebarModalSection
      [hideSeparator]="true"
    >
      <h4
        class="user-view__section-title"
        (click)="isOpenSection.contacts = !isOpenSection.contacts"
      >
        Contacts

        <span
          class="material-icons user-view__section-title-icon"
          [class.open]="isOpenSection.contacts"
        >
          keyboard_arrow_down
        </span>
      </h4>

      <div
        class="row"
        *ngIf="isOpenSection.contacts"
        @slideToggle
      >
        <adxad-sidebar-modal-info-block
          class="col-6"
          label="Email"
          *ngIf="user.email"
        >
          <a
            class="phone-number"
            href="mailto:{{ user.email }}"
          >
            {{ user.email }}
          </a>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="col-6"
          label="Contact phone"
          *ngIf="user.phoneNumber && roles.isAdmin()"
        >
          <a
            class="phone-number"
            href="tel:{{ user.phoneNumber }}"
          >
            {{ user.phoneNumber }}
          </a>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          class="languages-list col-6"
          label="Languages"
          *ngIf="user.languages.length"
        >
          <span
            class="languages-list__item"
            *ngFor="let lang of user.languages"
          >
            {{ lang }}
          </span>
        </adxad-sidebar-modal-info-block>

        <adxad-sidebar-modal-info-block
          label="Messengers"
          class="col-12"
          *ngIf="user.messengers.length"
        >
          <adxad-messengers-list [list]="user.messengers"></adxad-messengers-list>
        </adxad-sidebar-modal-info-block>
      </div>
    </section>

    <ng-container *ngIf="user.role === 'Manager'">
      <adxad-sidebar-modal-bar>
        {{ user.publishers && user.publishers.length }} Publishers

        <adxad-sidebar-modal-launch-btn
          *ngIf="user.publishers?.length"
          (click)="openPublishersGrid()"
        ></adxad-sidebar-modal-launch-btn>

        <button
          adxadSidebarModalBarAction
          adxadButton
          class="red"
          icon="add"
          (click)="openPublisherForm()"
        >
          {{ 'Create publisher' | uppercase }}
        </button>
      </adxad-sidebar-modal-bar>

      <ng-container *ngIf="user.publishers?.length">
        <adxad-sidebar-modal-search
          (filter)="filter($event)"
          *ngIf="user.publishers.length"
        ></adxad-sidebar-modal-search>

        <adxad-sidebar-modal-list>
          <div
            class="user-view__publisher"
            *ngFor="let publisher of filtered"
            (click)="openPublisherView(publisher.id)"
          >
            <adxad-entity-status
              [entityStatus]="publisher.status"
              [shortView]="true"
            ></adxad-entity-status>
            {{ publisher.email }}
          </div>
        </adxad-sidebar-modal-list>
      </ng-container>

      <section
        class="not-created"
        *ngIf="!user.publishers?.length"
      >
        <img
          class="not-creates__icon"
          src="/assets/images/svg/not-found-icon.svg"
          alt="No publishers"
        />
        <h2 class="not-created__title">No publishers</h2>
        <p class="not-created__text">There`s no publishers yet.</p>
      </section>
    </ng-container>
  </adxad-sidebar-modal-body>

  <adxad-sidebar-modal-actions>
    <button
      adxadSidebarModalAction
      actionType="main-gray"
      (click)="openStatisticsGrid()"
      *ngIf="roles.isManager()"
    >
      <span class="material-icons">query_stats</span>
      Statistics
    </button>

    <button
      adxadSidebarModalAction
      actionType="main-blue"
      (click)="openEditForm()"
    >
      <span class="material-icons">edit</span>
      Edit
    </button>

    <button
      adxadSidebarModalAction
      [actionType]="
        user.status === userStatus.active ? 'main-red' : 'main-green'
      "
      (click)="
        changeStatus(
          user.status === userStatus.active
            ? userStatus.blocked
            : userStatus.active
        )
      "
    >
      <span class="material-icons"> {{ user.status === userStatus.active ? 'stop' : 'play_arrow' }} </span>

      {{ user.status === userStatus.active ? 'Block' : 'Activate' }}
    </button>
  </adxad-sidebar-modal-actions>
</adxad-sidebar-modal-container>
