import { Injectable } from '@angular/core';
import {
  Config,
  EntityResponse,
  MultiChangeStatus,
  MultiChangeStatusResponse,
  Request,
  UserDetailView,
  UserFormView,
  UsersData
} from '../../core/interfaces';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SspService } from '../../core/services/ssp.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private config: Config = environment;

  constructor(
    private http: HttpClient,
    private sspService: SspService
  ) {}

  /**
   * Get users grid
   * @param request
   */
  getUsers(request: Request): Observable<UsersData> {
    return this.http.get<UsersData>(`${this.config.ssp_endpoint}/users/grid`, {
      params: this.sspService.prepareRequest(request)
    });
  }

  /**
   * Get user details info
   *
   * @return {Observable<UserDetailView>}
   * @param {Request} request
   */
  getUser(request: Request): Observable<UserDetailView> {
    return this.http.get<UserDetailView>(`${this.config.ssp_endpoint}/user/get`, { params: this.sspService.prepareRequest(request) });
  }

  /**
   * Get user form data
   *
   * @return {Observable<UserFormView>}
   * @param {Request} request
   */
  getUserForm(request: Request): Observable<UserFormView> {
    return this.http.get<UserFormView>(`${this.config.ssp_endpoint}/user/getForm`, { params: this.sspService.prepareRequest(request) });
  }

  addUser(data: UsersData): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post<EntityResponse>(`${this.config.ssp_endpoint}/user/create`, data, { headers });
  }

  editUser(data: UsersData): Observable<EntityResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<EntityResponse>(`${this.config.ssp_endpoint}/user/edit`, data, { headers });
  }

  /**
   * Change user status
   *
   * @param {MultiChangeStatus} data
   */
  changeUsersStatus(data: MultiChangeStatus): Observable<MultiChangeStatusResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put<MultiChangeStatusResponse>(`${this.config.ssp_endpoint}/users/status`, data, { headers });
  }
}
