import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Dict, Dicts, Messenger } from '../../../../core/interfaces';
import { AdxadModalRef } from '../../../../ui/modules/modal/modal-ref';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { AdxadModalConfig } from '../../../../ui/modules/modal/modal-config';
import { MessageService } from '../../../../core/services/message.service';
import { SspService } from '../../../../core/services/ssp.service';
import { takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { UsersService } from '../../users.service';
import { AdxadValidators } from '../../../../core/validators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public isLoadingSubmit = false;
  public isNewUser = true;
  public showPassword = true;
  public roles = [
    { id: 'ROLE_MANAGER', value: 'Manager' },
    { id: 'ROLE_ADMIN', value: 'Administrator' }
  ];
  public dicts = {} as Dicts;
  public selectedLangs: Dict[] = [];

  private searchValue = '';
  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadModalRef,
    private fb: UntypedFormBuilder,
    private alerts: AlertsComponent,
    public config: AdxadModalConfig,
    private messageService: MessageService,
    private sspService: SspService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    if (this.config.data && this.config.data.id) {
      this.isNewUser = false;
    }

    this.createForm();

    if (this.isNewUser) {
      this.loadData();
    } else {
      this.loadUserData();
    }
  }

  get basicFormGroup(): UntypedFormGroup {
    return this.form.get('basic') as UntypedFormGroup;
  }

  get profileFormGroup(): UntypedFormGroup {
    return this.form.get('profile') as UntypedFormGroup;
  }

  /**
   * Create user form
   */
  createForm(): void {
    this.form = this.fb.group({
      basic: this.fb.group({
        firstName: ['', [Validators.required, Validators.minLength(3), AdxadValidators.latinChars]],
        lastName: ['', [Validators.required, Validators.minLength(3), AdxadValidators.latinChars]],
        email: ['', [Validators.required, Validators.email, AdxadValidators.latinChars]],
        password: ['', [Validators.minLength(6)]],
        role: ['', Validators.required]
      }),
      profile: this.fb.group({
        phone: '',
        messengers: this.fb.array([]),
        languages: [[]]
      })
    });

    if (this.isNewUser) {
      const passwordControl = this.basicFormGroup.get('password');
      passwordControl.setValidators([Validators.required, Validators.minLength(6)]);
      passwordControl.updateValueAndValidity();
    }

    if (!this.isNewUser) {
      this.form.addControl('id', this.fb.control(this.config.data.id));
    }
  }

  /**
   * Load dictionaries
   * If error -> close modal
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const request = {
      limit: 200,
      page: 1
    };

    const langRequest = {
      limit: 200,
      page: 1,
      ids: [...this.profileFormGroup.get('languages').value]
    };

    const forkList = [this.sspService.getMessengers(request), this.sspService.getLanguages(langRequest)];

    forkJoin(forkList)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (results: any) => {
          this.isLoading = false;
          this.dicts.messengers = results[0];
          this.dicts.languages = results[1];
          this.dicts.languages.data.forEach(x => (x.selected = false));
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Load dicts
   */
  loadItems(dictId: string): void {
    const dict = this.dicts[dictId];

    if (dict.isLoading) {
      return;
    }

    dict.isLoading = true;

    const request: any = {
      limit: 200,
      page: dict.isLazy && dict.meta ? dict.meta.current_page + 1 : 1
    };

    if (this.searchValue) {
      request.search = this.searchValue;
    }

    this.sspService
      .getLanguages(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;
          dict.data = dict.data.concat(result.data);

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * Load filtered dicts
   *
   * @param {string} value
   * @param dictId
   */
  filterItem(value: string, dictId: string): void {
    const dict = this.dicts[dictId];

    if (dict.isLoading) {
      return;
    }

    this.searchValue = value;
    dict.isLoading = true;
    dict.data = [];

    const request = {
      limit: 200,
      page: 1,
      search: value
    };

    this.sspService
      .getLanguages(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          dict.isLoading = false;
          dict.data = result.data;

          if (result.meta) {
            dict.meta = result.meta;
            dict.isLazy = dict.meta.total > dict.data.length;
          }
        },
        error: () => (dict.isLoading = false)
      });
  }

  /**
   * Load detail user info
   */
  loadUserData(): void {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    this.usersService
      .getUserForm({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;

          const userData = result.data;

          if (userData.profile.languages.length) {
            this.selectedLangs = userData.profile.languages as Dict[];
            userData.profile.languages = userData.profile.languages.map(x => x.id);
          }

          this.form.patchValue(userData);

          if (userData.profile.messengers.length) {
            userData.profile.messengers.forEach(x => this.addMessenger(x));
          }

          this.loadData();
        },
        error: () => (this.isLoading = false)
      });
  }

  get getMessengers(): UntypedFormArray {
    return this.profileFormGroup.get('messengers') as UntypedFormArray;
  }

  /**
   * @return dict of not selected languages
   */
  get filteredLanguages(): Dict[] {
    return this.dicts.languages.data.filter(x => !x.selected);
  }

  /**
   * @return {UntypedFormControl} languages form control
   */
  get languages(): UntypedFormControl {
    return this.profileFormGroup.get('languages') as UntypedFormControl;
  }

  get baseValidation(): boolean {
    const controls = ['basic.firstName', 'basic.lastName', 'basic.email', 'basic.password', 'basic.role'];
    return !!controls.filter(x => {
      return this.form.get(x).touched && this.form.get(x).invalid;
    }).length;
  }

  /**
   * Add id of lang in language control
   *
   * @param {string} id
   */
  selectLang(id: string): void {
    const value = [...this.languages.value];

    if (value.indexOf(id) !== -1) {
      return;
    }

    const obj = this.dicts.languages.data.find(x => x.id === id);
    this.selectedLangs.push(obj);

    value.push(id);
    this.languages.setValue(value);
    this.form.markAsDirty();
  }

  /**
   * Remove language from control
   *
   * @param {number} i
   */
  removeLang(i: number): void {
    const value = [...this.languages.value];
    value.splice(i, 1);
    this.languages.setValue(value);
    this.selectedLangs.splice(i, 1);
    this.form.markAsDirty();
  }

  /**
   * Add messenger to form
   */
  addMessenger(data?: Messenger): void {
    this.getMessengers.push(
      this.fb.group({
        type: data.type,
        info: [data.info, [Validators.required, AdxadValidators.latinChars, Validators.minLength(3)]]
      })
    );
  }

  /**
   * Submit form
   */
  submit(): void {
    if (!this.form.valid || this.isLoadingSubmit) {
      return;
    }

    this.isLoadingSubmit = true;
    const data = this.form.value;

    if (data['profile']['languages'].length) {
      data['profile']['languages'].sort();
    }

    const method = this.isNewUser ? this.usersService.addUser(data) : this.usersService.editUser(data);

    method.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: response => {
        if (response.status === 'OK') {
          this.isLoadingSubmit = false;

          this.alerts.success('User successfully ' + (this.isNewUser ? 'created' : 'changed'), 3000);
          this.messageService.add('reload-users-grid', { submit: true });
          this.closeModal(true);
        }
      },
      error: () => (this.isLoadingSubmit = false)
    });
  }

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
