import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { AdxadSidebarModalRef } from '../../../../ui/modules/sidebar-modal/sidebar-modal-ref';
import { AdxadModal } from '../../../../ui/modules/modal/modal.service';
import { QueryParamsService } from '../../../../core/services/query-params.service';
import { AdxadSidebarModalConfig } from '../../../../ui/modules/sidebar-modal/sidebar-modal-config';
import { AlertsComponent } from '../../../../ui/components/alerts/alerts.component';
import { Status, UserDetail } from '../../../../core/interfaces';
import { takeUntil } from 'rxjs/operators';
import { UserFormComponent } from '../user-form/user-form.component';
import { MessageService } from '../../../../core/services/message.service';
import { UsersService } from '../../users.service';
import { SspService } from '../../../../core/services/ssp.service';
import { PublisherFormComponent } from '../../../publishers/components/publisher-form/publisher-form.component';
import { PublisherViewComponent } from '../../../publishers/components/publisher-view/publisher-view.component';
import { AdxadSidebarModal } from '../../../../ui/modules/sidebar-modal/sidebar-modal.service';
import { BreadcrumbsService } from '../../../../core/services/breadcrumbs.service';
import { Router } from '@angular/router';
import { APP_ROUTE } from '../../../../core/routes';
import { RolesService } from '../../../../core/services/roles.service';
import { slideToggleAnimation } from '../../../../ui/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss'],
  animations: [slideToggleAnimation]
})
export class UserViewComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isLoadingStatus = false;
  public user: UserDetail;
  public filtered = [];
  public userStatus = Status;

  public isOpenSection = {
    contacts: true
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private modalRef: AdxadSidebarModalRef,
    private modal: AdxadModal,
    private queryParamsService: QueryParamsService,
    public config: AdxadSidebarModalConfig,
    private alerts: AlertsComponent,
    private messageService: MessageService,
    private usersService: UsersService,
    private sidebarModal: AdxadSidebarModal,
    public breadcrumbs: BreadcrumbsService,
    private sspService: SspService,
    private router: Router,
    public roles: RolesService
  ) {}

  ngOnInit(): void {
    if (!this.config || !this.config.data || !this.config.data.id) {
      this.alerts.error('Oops something went wrong', 3000);
      this.closeModal();
    }

    this.queryParamsService.add('sm_userView', this.config.data.id);
    this.loadData();
  }

  /**
   * Load user data
   */
  loadData(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.usersService
      .getUser({ id: this.config.data.id })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (result: any) => {
          this.isLoading = false;

          if (result.status == 'OK') {
            this.user = result.data;
            this.filtered = this.user.publishers;
          }
        },
        error: () => {
          this.isLoading = false;
          this.closeModal();
        }
      });
  }

  /**
   * Open user edit form
   */
  openEditForm(): void {
    this.modal
      .open(UserFormComponent, {
        width: '640px',
        data: this.user
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadData();
          }
        }
      });
  }

  /**
   * Set active or blocked status
   *
   * @param {number} status
   */
  changeStatus(status: string): void {
    if (status === undefined || this.isLoadingStatus) {
      return;
    }

    this.isLoadingStatus = true;
    const data = {
      ids: [this.config.data.id],
      status: status
    };

    this.usersService
      .changeUsersStatus(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: result => {
          this.isLoadingStatus = false;

          if (result.status === 'OK') {
            if (!result.data || !result.data.length) {
              this.alerts.error('User has not changed status', 3000);
              return;
            }

            this.messageService.add('reload-users-grid', { submit: true });

            this.user.status = result.data[0].status;
            const message =
              'The user is ' + (this.user.status == this.userStatus.active ? this.userStatus.active : this.userStatus.blocked);
            this.alerts.success(message, 3000);
          }
        },
        error: () => (this.isLoadingStatus = false)
      });
  }

  /**
   * Open create publisher form
   */
  openPublisherForm(): void {
    this.modal
      .open(PublisherFormComponent, {
        width: '680px',
        data: {
          managerId: this.user.id
        }
      })
      .afterClosed.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: value => {
          if (value && value.submit) {
            this.loadData();
          }
        }
      });
  }

  /**
   * Close user view modal
   * Open detail publisher view
   *
   * @param {string} id of project
   */
  openPublisherView(id: string): void {
    this.closeModal();

    this.breadcrumbs.add({
      component: UserViewComponent,
      id: this.config.data.id
    });

    this.sidebarModal.open(PublisherViewComponent, {
      data: {
        id: id
      }
    });
  }

  openPublishersGrid() {
    if (!this.user.publishers.length) {
      return;
    }

    this.router
      .navigate([APP_ROUTE.publishers], {
        queryParams: {
          f_manager: '[{"id":"' + this.user.id + '","value":"' + this.user.email + '"}]'
        }
      })
      .then(() => this.closeModal());
  }

  /**
   * Filter publishers by input value
   * @param {string} value
   */
  filter(value: string): void {
    this.filtered = this.user.publishers.filter(x => x.email.indexOf(value) !== -1);
  }

  /**
   * Open filtered statistics grid by spot id
   */
  openStatisticsGrid() {
    this.router
      .navigate([APP_ROUTE.statistic], {
        queryParams: {
          g_manager: 1,
          f_manager: '[{"id":"' + this.user.id + '","value":"' + this.user.email + '"}]'
        }
      })
      .then(() => this.closeModal());
  }

  /**
   * Close modal
   */
  closeModal(): void {
    this.modalRef.close();
  }

  ngOnDestroy(): void {
    this.queryParamsService.remove('sm_userView');
  }
}
